@import '../../variables';

@media screen and (min-width: 768px) {
    #login-container{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100dvh;
        #login-data-container{
            width: 30vw;
            max-width: 450px;
            padding: 0 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            #business-img{
                width: 90%;
                margin: 0;
                align-self: center;
            }
            #login-title{
                margin: 10vh 0 0 0;
                padding: 0;
                display: block;
                color: $text-gray;
                text-align: center;
                font-size: 1.6em;
            }
            #login-message{
                margin: 0 0 6vh 0;
                padding: 0;
                display: block;
                color: $text-gray-light;
                text-align: center;
                font-size: 1.2em;
            }
            .form-control{
                text-align: center;
                margin: 20px 0;
                font-size: 1em;
                color: $text-gray;
                border: none;
                background-color: transparent;
                border-bottom: 1.5px solid $text-gray;
                border-radius: 0;
            }
            #login-btn{
                display: block;
                margin: 3vh auto 3vh auto;
                padding: 1vh 0;
                font-size: 1em;
                width: 70%;
                color: $text-white;
                background-color: $bg-primary;
            }
            #forgot-password{
                margin: 5px 0 0 0;
                padding: 0;
                display: block;
                color: $text-gray-light;
                text-align: center;
                font-weight: 500;
                font-size: 1em;
                text-decoration: underline;
                cursor: pointer;
            }
            #register{
                margin: 10px 0 0 0;
                padding: 0;
                display: block;
                color: $text-gray-light;
                text-align: center;
                font-weight: 500;
                font-size: 1em;
                cursor: pointer;
            }
        }
        #carousel-container{
            height: 100dvh;
            display: flex;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            flex: 1;
            position: relative;
            transition: 1s;
            #client-login-wave{
                width: 100%;
                height: 37vh;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 0;
                background-image: url('/images/client-login-wave.svg');
                background-size: cover;
                background-position: top;
                background-repeat: no-repeat;
                display: flex;
                justify-content: end;
                align-items: center;
                flex-direction: column;
                #carousel-text-container{
                    width: 85%;
                    display: block;
                    margin: 0 auto 25px auto;
                    text-align: center;
                    h1, h2, h3, h4, h5, h6, p{
                        margin: 0;
                        padding: 3px 0;
                        display: block;
                        color: $body-bg;
                        transition: 1s;
                    }
                    p{
                        font-size: 1.2em;
                    }
                    h6{
                        font-size: 1.5em;
                    }
                    h5{
                        font-size: 1.7em;
                    }
                    h4{
                        font-size: 2em;
                    }
                    h3{
                        font-size: 2em;
                    }
                    h2{
                        font-size: 3em;
                    }
                    h1{
                        font-size: 3.5em;
                    }
                }
                #carousel-opt-list{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto 20px auto;
                    padding: 0;
                    list-style: none;
                    z-index: 1;
                    .carousel-opt-item{
                        margin: 0 5px;
                        padding: 0;
                        display: block;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        background-color: transparent;
                        border: 1.5px solid $body-bg;
                        cursor: pointer;
                        transition: background-color 1s;
                        &:hover, &.active{
                            background-color: $body-bg;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    #login-container{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100dvh;
        #login-data-container{
            width: 100%;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            #business-img{
                width: 80%;
                margin: 0;
                align-self: center;
            }
            #login-title{
                margin: 10vh 0 0 0;
                padding: 0;
                display: block;
                color: $text-gray;
                text-align: center;
                font-size: 1.6em;
            }
            #login-message{
                margin: 0 0 6vh 0;
                padding: 0;
                display: block;
                color: $text-gray-light;
                text-align: center;
                font-size: 1.2em;
            }
            .form-control{
                text-align: center;
                margin: 20px auto;
                font-size: 1em;
                color: $text-gray;
                border: none;
                background-color: transparent;
                border-bottom: 1.5px solid $text-gray;
                border-radius: 0;
                width: 90%;
            }
            #login-btn{
                display: block;
                margin: 3vh auto 3vh auto;
                padding: 1vh 0;
                font-size: 1em;
                width: 70%;
                color: $text-white;
                background-color: $bg-primary;
            }
            #forgot-password{
                margin: 5px 0 0 0;
                padding: 0;
                display: block;
                color: $text-gray-light;
                text-align: center;
                font-weight: 500;
                font-size: 1em;
                text-decoration: underline;
                cursor: pointer;
            }
            #register{
                margin: 10px 0 0 0;
                padding: 0;
                display: block;
                color: $text-gray-light;
                text-align: center;
                font-weight: 500;
                font-size: 1em;
                cursor: pointer;
            }
        }
        #carousel-container{
            display: none;
        }
    }
}
